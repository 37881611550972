import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  HomeHeader,
  Banner,
  BannerButton,
  BannerImage,
  BannerButton1,
} from "../utils"
import img from "../images/BGgamepads28.png"
import { ChopyHeader } from "../utils"
import AboutImg from "../images/MainPage.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <HomeHeader img={img}>
      <Banner>
        <br></br>
        <iframe
          width="768"
          height="432"
          src="https://www.youtube.com/embed/a42lH3YONT0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="true"
        ></iframe>

        <ChopyHeader>
          {" "}
          <Link
            to="https://bit.ly/3QJ35C5"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <BannerImage>
              {" "}
              <img src={AboutImg} alt="Super Choppy Orc"></img>
            </BannerImage>
          </Link>
        </ChopyHeader>

        <Link
          to="https://bit.ly/3QJ35C5"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <BannerButton Linkstyle={{ margin: "2rem auto" }}>
            Wishlist Now!
          </BannerButton>
        </Link>

        <Banner title="eddynardo games"></Banner>
        <Banner>
          <Link
            to="https://www.coolmathgames.com"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <BannerButton1 Linkstyle={{ margin: "1rem auto" }}>
              Check out my games on Coolmathgames.com
            </BannerButton1>
          </Link>
        </Banner>
      </Banner>
    </HomeHeader>
  </Layout>
)

export default IndexPage
